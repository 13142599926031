/*
                        _   _       _a_a
            _   _     _{.`=`.}_    {/ ''\_
      _    {.`'`.}   {.'  _  '.}  {|  ._oo)
     { \  {/ .-. \} {/  .' '.  \} {/  |
~^~^~`~^~`~^~`~^~`~^~^~`^~^~`^~^~^~^~^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~^~`^~
/* ----------------------------------------------------------------------------
[Master Stylesheet]

Project:        Tradiswiss Shop
Version:        1.0
Last change:    19/10/2016
Created by:     Yves Engetschwiler (info@bee-interactive.ch)
// --------------------------------------------------------------------------*/
.admin-edit-bar {
  background: #0098af;
  padding: 10px 20px 7px 20px;
  color: #fff;
  line-height: 30px;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
}
.admin-edit-bar img {
  margin-top: -8px;
  margin-right: 10px;
}
.admin-edit-bar .margin-left-20 {
  margin-left: 20px;
}
.admin-edit-bar a {
  color: #fff;
  display: inline-block;
}
.admin-edit-bar a:hover {
  color: #fff;
  text-decoration: underline;
}
.fr-window,
.fr-window [class^="fr-"],
.fr-overlay,
.fr-overlay [class^="fr-"],
.fr-spinner,
.fr-spinner [class^="fr-"] {
  box-sizing: border-box;
}
.fr-window {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font: 13px/20px "Lucida Sans", "Lucida Sans Unicode", "Lucida Grande", Verdana, Arial, sans-serif;
  transform: translateZ(0px);
}
.fr-overlay {
  z-index: 99998;
}
.fr-window {
  z-index: 99999;
}
.fr-spinner {
  z-index: 100000;
}
.fr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.fr-overlay-background {
  float: left;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.9;
}
.fr-overlay-ui-fullclick .fr-overlay-background {
  background: #292929;
  opacity: 1;
}
.fr-window.fr-mobile-touch,
.fr-overlay.fr-mobile-touch {
  position: absolute;
  overflow: visible;
}
.fr-measured {
  margin: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
}
.fr-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-pages {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fr-page {
  position: absolute;
  width: 100%;
  height: 100%;
}
.fr-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}
.fr-hovering-clickable .fr-container {
  cursor: pointer;
}
.fr-ui-inside .fr-container {
  padding: 20px 20px;
}
.fr-ui-inside.fr-no-sides .fr-container {
  padding: 20px;
}
.fr-ui-outside .fr-container {
  padding: 20px 82px;
}
.fr-ui-outside.fr-no-sides .fr-container {
  padding: 20px 48px;
}
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 700px) {
  .fr-ui-inside .fr-container {
    padding: 12px 12px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 12px;
  }
  .fr-ui-outside .fr-container {
    padding: 12px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 12px 48px;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  .fr-ui-inside .fr-container {
    padding: 0px 0px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 0px;
  }
  .fr-ui-outside .fr-container {
    padding: 0px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 0px 48px;
  }
}
.fr-ui-fullclick .fr-container {
  padding: 0;
}
.fr-ui-fullclick.fr-no-sides .fr-container {
  padding: 0;
}
.fr-ui-fullclick.fr-type-video .fr-container {
  padding: 0px 62px;
}
.fr-ui-fullclick.fr-no-sides.fr-type-video .fr-container {
  padding: 48px 0px;
}
.fr-overflow-y .fr-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fr-content,
.fr-content-background {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible;
}
.fr-content-element {
  float: left;
  width: 100%;
  height: 100%;
}
.fr-content-background {
  background: #101010;
}
.fr-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #efefef;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.fr-info-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  line-height: 1%;
  filter: none;
  opacity: 1;
}
.fr-ui-inside .fr-info-background {
  opacity: 0.8;
  zoom: 1;
}
.fr-ui-outside .fr-info-background {
  background: #0d0d0d;
}
.fr-content .fr-info {
  top: auto;
  bottom: 0;
}
.fr-info-padder {
  display: block;
  overflow: hidden;
  padding: 12px;
  position: relative;
  width: auto;
}
.fr-caption {
  width: auto;
  display: inline;
  white-space: wrap;
}
.fr-position {
  color: #b3b3b3;
  float: right;
  line-height: 21px;
  opacity: 0.99;
  position: relative;
  text-align: right;
  margin-left: 15px;
  white-space: nowrap;
}
.fr-position-outside,
.fr-position-inside {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  width: auto;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 11px;
  line-height: 20px;
  text-shadow: 0 1px 0 rgba(0,0,0,0.4);
  display: none;
  overflow: hidden;
  white-space: nowrap;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.fr-position-inside {
  border: 0;
}
.fr-position-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  opacity: 0.8;
  zoom: 1 /* oldIE */;
}
.fr-position-text {
  color: #b3b3b3;
}
.fr-position-outside .fr-position-text,
.fr-position-inside .fr-position-text {
  float: left;
  position: relative;
  text-shadow: 0 1px 1px rgba(0,0,0,0.3);
  opacity: 1;
}
.fr-ui-outside .fr-position-outside {
  display: block;
}
.fr-ui-outside .fr-info .fr-position {
  display: none;
}
.fr-ui-inside.fr-no-caption .fr-position-inside {
  display: block;
}
.fr-info a,
.fr-info a:hover {
  color: #ccc;
  border: 0;
  background: none;
  text-decoration: underline;
}
.fr-info a:hover {
  color: #eee;
}
.fr-ui-outside.fr-no-caption .fr-info {
  display: none;
}
.fr-ui-inside.fr-no-caption .fr-caption {
  display: none;
}
.fr-stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}
.fr-stroke-vertical {
  width: 1px;
  height: 100%;
}
.fr-stroke-horizontal {
  padding: 0 1px;
}
.fr-stroke-right {
  left: auto;
  right: 0;
}
.fr-stroke-bottom {
  top: auto;
  bottom: 0;
}
.fr-stroke-color {
  float: left;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.08);
}
.fr-info .fr-stroke-color {
  background: rgba(80,80,80,0.3);
}
.fr-ui-outside.fr-has-caption .fr-content .fr-stroke-bottom {
  display: none;
}
.fr-ui-fullclick .fr-stroke {
  display: none;
}
.fr-ui-fullclick .fr-content-background {
  box-shadow: none;
}
.fr-info .fr-stroke-top {
  display: none;
}
.fr-side {
  position: absolute;
  top: 50%;
  width: 54px;
  height: 72px;
  margin: 0 9px;
  margin-top: -36px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  zoom: 1;
}
.fr-side-previous {
  left: 0;
}
.fr-side-next {
  right: 0;
  left: auto;
}
.fr-side-disabled {
  cursor: default;
}
.fr-side-hidden {
  display: none !important;
}
.fr-side-button {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.fr-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #101010;
  background-color: #ccc;
}
.fr-side-button-icon {
  float: left;
  position: relative;
  height: 100%;
  width: 100%;
  zoom: 1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-side {
    width: 54px;
    height: 60px;
    margin: 0;
    margin-top: -30px;
  }
  .fr-side-button {
    width: 48px;
    height: 60px;
    margin: 0 3px;
  }
}
.fr-close {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.fr-close-background,
.fr-close-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 26px;
  width: 26px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.fr-close-background {
  background-color: #000;
}
.fr-thumbnails {
  position: absolute;
  overflow: hidden;
}
.fr-thumbnails-disabled .fr-thumbnails {
  display: none !important;
}
.fr-thumbnails-horizontal .fr-thumbnails {
  width: 100%;
  height: 12%;
  min-height: 74px;
  max-height: 160px;
  bottom: 0;
}
.fr-thumbnails-vertical .fr-thumbnails {
  height: 100%;
  width: 10%;
  min-width: 74px;
  max-width: 160px;
  left: 0;
}
.fr-thumbnails,
.fr-thumbnails * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fr-thumbnails-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
}
.fr-thumbnails-vertical .fr-thumbnails-wrapper {
  top: 50%;
  left: 0;
}
.fr-thumbnails-slider {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  zoom: 1;
}
.fr-thumbnails-slider-slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.fr-thumbnails-thumbs {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.fr-thumbnails-slide {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.fr-thumbnail-frame {
  position: absolute;
  zoom: 1;
  overflow: hidden;
}
.fr-thumbnail {
  position: absolute;
  width: 30px;
  height: 100%;
  left: 50%;
  top: 50%;
  zoom: 1;
  cursor: pointer;
  margin: 0 10px;
}
.fr-ltIE9 .fr-thumbnail * {
  overflow: hidden /* IE6(7) */;
  z-index: 1;
  zoom: 1;
}
.fr-thumbnail-wrapper {
  position: relative;
  background: #161616;
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  display: inline /* IE6(7) */;
  z-index: 0 /* IE8 */;
}
.fr-thumbnail-overlay {
  cursor: pointer;
}
.fr-thumbnail-active .fr-thumbnail-overlay {
  cursor: default;
}
.fr-thumbnail-overlay,
.fr-thumbnail-overlay-background,
.fr-thumbnail-overlay-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  overflow: hidden;
  border-style: solid;
  border-color: transparent;
}
.fr-ltIE9 .fr-thumbnail-overlay-border {
  border-width: 0 !important;
}
.fr-thumbnail .fr-thumbnail-image {
  position: absolute;
  opacity: 0.85;
  max-width: none;
}
.fr-thumbnail:hover .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  opacity: 0.99;
}
.fr-thumbnail-active .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  opacity: 0.35;
}
.fr-thumbnail-active {
  cursor: default;
}
.fr-thumbnail-loading,
.fr-thumbnail-loading-background,
.fr-thumbnail-loading-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-thumbnail-loading-background {
  background-color: #161616;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: relative;
  float: left;
}
.fr-thumbnail-loading-icon {
  display: none;
}
.fr-thumbnail-error .fr-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
}
.fr-thumbnails-side {
  float: left;
  height: 100%;
  width: 28px;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
}
.fr-thumbnails-side-previous {
  margin-left: 12px;
}
.fr-thumbnails-side-next {
  margin-right: 12px;
}
.fr-thumbnails-vertical .fr-thumbnails-side {
  height: 28px;
  width: 100%;
  margin: 10px 0;
}
.fr-thumbnails-vertical .fr-thumbnails-side-previous {
  margin-top: 20px;
}
.fr-thumbnails-vertical .fr-thumbnails-side-next {
  margin-bottom: 20px;
}
.fr-thumbnails-side-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.fr-thumbnails-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  -moz-transition: background-color 0.2s ease-in;
  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
  background-color: #333;
  cursor: pointer;
  border-radius: 4px;
}
.fr-thumbnails-side-button:hover .fr-thumbnails-side-button-background {
  background-color: #3b3b3b;
}
.fr-thumbnails-side-button-disabled * {
  cursor: default;
}
.fr-thumbnails-side-button-disabled:hover .fr-thumbnails-side-button-background {
  background-color: #333;
}
.fr-thumbnails-side-button-icon {
  height: 42px;
  width: 42px;
  position: absolute;
/*top: -7px
    left: -7px*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-thumbnails-vertical .fr-thumbnails-side,
.fr-thumbnails-vertical .fr-thumbnails-thumbs,
.fr-thumbnails-vertical .fr-thumbnail-frame {
  clear: both;
}
.fr-window-ui-fullclick .fr-thumbnails {
  background: #090909;
}
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-horizontal .fr-info .fr-stroke-bottom {
  display: block !important;
}
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-vertical .fr-info .fr-stroke-left {
  display: block !important;
}
.fr-thumbnails-horizontal .fr-thumbnails-thumbs {
  padding: 12px 5px;
}
.fr-thumbnails-vertical .fr-thumbnails-thumbs {
  padding: 5px 12px;
}
.fr-thumbnails-measured .fr-thumbnails-thumbs {
  padding: 0 !important;
}
@media all and (min-height: 700px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 16px 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 16px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 16px;
  }
}
@media all and (min-height: 980px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 20px 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 20px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 20px;
  }
}
@media all and (min-width: 1200px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 8px 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 16px;
  }
}
@media all and (min-width: 1800px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 10px 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 10px 0;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  .fr-thumbnails-horizontal .fr-thumbnails {
    display: none !important;
  }
}
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-thumbnails-vertical .fr-thumbnails {
    display: none !important;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-page {
    min-width: 100%;
  }
  .fr-page {
    min-height: 100%;
  }
}
.fr-window-ui-fullclick .fr-side-next-outside,
.fr-window-ui-fullclick .fr-side-previous-outside,
.fr-window-ui-fullclick .fr-close-outside,
.fr-window-ui-fullclick .fr-content .fr-side-next,
.fr-window-ui-fullclick .fr-content .fr-side-previous,
.fr-window-ui-fullclick .fr-content .fr-close,
.fr-window-ui-fullclick .fr-content .fr-info,
.fr-window-ui-outside .fr-side-next-fullclick,
.fr-window-ui-outside .fr-side-previous-fullclick,
.fr-window-ui-outside .fr-close-fullclick,
.fr-window-ui-outside .fr-content .fr-side-next,
.fr-window-ui-outside .fr-content .fr-side-previous,
.fr-window-ui-outside .fr-content .fr-close,
.fr-window-ui-outside .fr-content .fr-info,
.fr-window-ui-inside .fr-page > .fr-info,
.fr-window-ui-inside .fr-side-next-fullclick,
.fr-window-ui-inside .fr-side-previous-fullclick,
.fr-window-ui-inside .fr-close-fullclick,
.fr-window-ui-inside .fr-side-next-outside,
.fr-window-ui-inside .fr-side-previous-outside,
.fr-window-ui-inside .fr-close-outside {
  display: none !important;
}
.fr-toggle-ui {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.fr-visible-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-visible-inside-ui .fr-ui-inside .fr-toggle-ui {
  opacity: 1;
}
.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.fr-ltIE9.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-ltIE9.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  display: none;
}
.fr-spinner {
  position: fixed;
  width: 52px;
  height: 52px;
  background: #101010;
  background: rgba(16,16,16,0.85);
  border-radius: 5px;
}
.fr-spinner div {
  position: absolute;
  top: 0;
  left: 0;
  height: 64%;
  width: 64%;
  margin-left: 18%;
  margin-top: 18%;
  opacity: 1;
  -webkit-animation: fresco-12 1.2s infinite ease-in-out;
  animation: fresco-12 1.2s infinite ease-in-out;
}
.fr-spinner div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 8px;
  margin-left: -1px;
  background: #fff;
  box-shadow: 0 0 1px rgba(0,0,0,0) /* fixes rendering in Firefox */;
}
.fr-spinner div.fr-spin-1 {
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.fr-spinner div.fr-spin-2 {
  -ms-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.fr-spinner div.fr-spin-3 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.fr-spinner div.fr-spin-4 {
  -ms-transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.fr-spinner div.fr-spin-5 {
  -ms-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.fr-spinner div.fr-spin-6 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.fr-spinner div.fr-spin-6 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.fr-spinner div.fr-spin-7 {
  -ms-transform: rotate(210deg);
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.fr-spinner div.fr-spin-8 {
  -ms-transform: rotate(240deg);
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.fr-spinner div.fr-spin-9 {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.fr-spinner div.fr-spin-10 {
  -ms-transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.fr-spinner div.fr-spin-11 {
  -ms-transform: rotate(330deg);
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.fr-spinner div.fr-spin-12 {
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-webkit-keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fr-thumbnail-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
}
.fr-thumbnail-spinner-spin {
  position: relative;
  float: left;
  margin: 8px 0 0 8px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255,255,255,0.2);
  border-right: 2px solid rgba(255,255,255,0.2);
  border-bottom: 2px solid rgba(255,255,255,0.2);
  border-left: 2px solid #fff;
  -webkit-animation: fr-thumbnail-spin 1.1s infinite linear;
  animation: fr-thumbnail-spin 1.1s infinite linear;
}
.fr-thumbnail-spinner-spin,
.fr-thumbnail-spinner-spin:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
@-webkit-keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fr-error {
  float: left;
  position: relative;
  background-color: #ca3434;
  width: 160px;
  height: 160px;
}
.fr-error-icon {
  position: absolute;
  width: 42px;
  height: 42px;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
}
.fr-window-skin-fresco .fr-side-button-icon,
.fr-window-skin-fresco .fr-close-icon,
.fr-window-skin-fresco .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("/img/fresco/skins/fresco/sprite.svg");
}
.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-close-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("/img/fresco/skins/fresco/sprite.png");
}
.fr-window-skin-fresco .fr-error-icon {
  background-position: -160px -126px;
}
.fr-window-skin-fresco .fr-content-background {
  background: #101010;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
}
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-content-background {
  box-shadow: none;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0,0,0,0.1);
}
.fr-window-skin-fresco .fr-side-button-background {
  background-color: transparent;
}
.fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
  background-position: -13px -14px;
}
.fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
  background-position: -93px -14px;
}
.fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -13px -214px;
}
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -93px -214px;
}
.fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-svg .fr-side-button-icon {
  opacity: 0.5;
}
.fr-window-skin-fresco.fr-svg .fr-side:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-next .fr-side-button-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon {
  opacity: 0.8;
}
.fr-window-skin-fresco.fr-svg .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-side-disabled:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side-disabled .fr-side-button-icon {
  opacity: 0.2;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-type-image .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-window-ui-fullclick.fr-showing-type-image .fr-side-disabled .fr-side-button-icon {
  background-image: none;
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
    background-position: 0px -300px;
  }
  .fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
    background-position: -48px -300px;
  }
  .fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
    background-position: 0px -420px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
    background-position: -48px -420px;
  }
}
.fr-window-skin-fresco.fr-window-ui-outside .fr-close-background {
  background-color: #363636;
}
.fr-window-skin-fresco.fr-window-ui-outside .fr-close:hover .fr-close-background {
  background-color: #434343;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close-background {
  background-color: rgba(17,51,17,0.2) 1;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-close:hover .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close:hover .fr-close-background {
  background-color: #191919;
}
.fr-window-skin-fresco .fr-close .fr-close-icon {
  background-position: -168px -8p;
}
.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  background-position: -210px -8px;
}
.fr-window-skin-fresco.fr-svg .fr-close .fr-close-icon {
  background-position: -210px -8px;
  opacity: 0.8;
}
.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,
.fr-window-skin-fresco.fr-mobile-touch .fr-close:hover .fr-close-icon {
  opacity: 1;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0,0,0,0.1);
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 -1px 4px rgba(0,0,0,0.3);
}
.fr-window-skin-fresco .fr-thumbnail-overlay-border {
  border-width: 1px;
  border-color: rgba(255,255,255,0.08) /* should remain rgba */;
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-overlay-border,
.fr-window-skin-fresco .fr-thumbnail-active:hover .fr-thumbnail-overlay-border {
  border: 0px;
}
.fr-window-skin-fresco .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -167px -49px;
}
.fr-window-skin-fresco .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -49px;
}
.fr-window-skin-fresco .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -167px -91px;
}
.fr-window-skin-fresco .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -91px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -293px -49px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -49px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -293px -91px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-icon {
  -moz-transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 0.8;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -167px -49px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -209px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -293px -49px;
}
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -335px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled {
  opacity: 0.5;
}
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -49px;
}
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -91px;
}
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -49px;
}
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -91px;
}
@-moz-keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------------------------------------------------
[Table of contents]
0. Fonts
1. Clearfix
2. Preloader
3. Typography
4. Headings
5. Main Nav
6. Main Content
7. Column Grid
8. Content Elements
9. Icons
10. Image Hover
11. Compatibility Rules
12. Footer
13. Lists
14. Blog
-------------------------------------------------------------------*/
/* =1. Clearfix
--------------------------------------------------------------------------------------------------------*/
.clear {
  clear: both;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
/* ----- clear fix for floats ----- */
.clearfix {
  display: inline-block;
}
* html .clearfix {
  height: 1%;
}
/* hides clearfix from IE-mac */
.clearfix {
  display: block;
}
/* end hide from IE-mac */
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.aligncenter {
  display: inline-block;
}
.aligncenter .padding {
  display: block;
  padding: 0 12px;
}
.alignnone {
  clear: both;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  height: 0;
  width: 0;
}
.centered {
  text-align: center;
}
.hidden {
  display: none;
  visibility: hidden;
}
/* Preventing font-rendering switches during CSS transitions inside Flexslider for Safari and Chrome under MacOSX */
*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
}
.flexslider {
  -webkit-transform: translateZ(0px);
}
/* End Clearfix
--------------------------------------------------------------------------------------------------------*/
/* =2. Preloader
--------------------------------------------------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999999;
}
#status {
  z-index: 28;
  position: absolute;
  color: #555 !important;
  top: 50%;
  height: 220px;
  width: 100%;
  margin-top: -115px;
}
/* horizontal centering */
.parent {
  width: 100%;
  margin: 0px auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 17px 35px;
  z-index: 11;
}
.large-bg-tiles .parent {
  padding: 55px 80px;
}
.large-bg-tiles .col--3-2 .parent {
  padding: 55px 160px;
}
#preloader .child {
  text-align: center;
}
/* vertical centering */
.parent {
  display: table;
}
.child {
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}
.parent .bottom {
  display: table-cell;
  vertical-align: bottom;
  padding: 0;
}
.parent .top {
  display: table-cell;
  vertical-align: top;
  padding: 0;
}
.box-parent .child {
  padding-top: 10px;
}
#search-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(29,29,29,0.85);
  z-index: 9999999;
  display: none;
  height: 100%;
  width: 100%;
}
body.search-visible #search-wrapper {
  display: block;
}
#search-wrapper input {
  display: inline-block !important;
  font-size: 24px;
  color: #fff;
  text-align: center;
  border-bottom: 2px solid rgba(255,255,255,0.3);
  padding: 12px 15px 12px;
  min-width: 550px;
}
.close-search {
  margin-top: 15px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  display: inline-block;
}
.close-search:hover {
  opacity: 1 !important;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
/* End Preloader
--------------------------------------------------------------------------------------------------------*/
/* =3. Typography
--------------------------------------------------------------------------------------------------------*/
html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
#wrap {
  z-index: 1001;
  position: relative;
  background: #f2f2f2;
}
html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
body {
  font-size: 14px;
  font-family: 'Droid Serif', Georgia, serif;
  color: #585858;
  background: #1d1d1d; /*-webkit-text-size-adjust: 100%;*/
  -moz-osx-font-smoothing: grayscale; /*-webkit-font-smoothing: antialiased;*/
}
strong {
  font-weight: 600;
}
p strong {
  color: #585858;
}
a,
a * {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
a,
a:hover,
a:focus {
  color: #2b2b2b;
  text-decoration: none;
  outline: 0;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  outline: 0;
}
*:focus,
*:after:focus,
*:before:focus {
  outline: 0;
}
p {
  line-height: 1.5;
  padding: 0;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  position: relative;
}
a {
  text-decoration: none;
}
p a {
  color: #585858;
  text-decoration: underline;
}
p a:hover {
  color: #2b2b2b;
  text-decoration: underline;
}
a p {
  color: #585858;
}
p.small {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  padding-top: 15px;
  padding-bottom: 0;
  margin-bottom: -5px;
  font-family: 'Droid Serif', Georgia, serif;
}
p.small.smaller {
  font-size: 10px;
  letter-spacing: 1px;
  opacity: 0.9;
}
#preloader p.small {
  color: #585858 !important;
  padding: 0 !important;
}
.child p.small {
  padding-top: 10px;
}
p.small.below-header {
  padding-top: 4px;
  margin-bottom: 12px;
}
p.details {
  opacity: 1;
  font-size: 110%;
  font-family: 'Droid Serif', Georgia, serif;
}
p.details.font-size-77-percent {
  font-size: 77%;
}
.font-size-77-percent {
  font-size: 77%;
}
.font-size-130-percent {
  font-size: 130% !important;
}
.font-size-140-percent {
  font-size: 140% !important;
}
.font-size-150-percent {
  font-size: 150% !important;
}
.font-size-160-percent {
  font-size: 160% !important;
}
span.serif {
  font-family: 'Droid Serif', Georgia, serif;
  font-size: 110%;
}
p.details .upper-part {
  font-size: 110%;
  color: #000;
  font-family: 'Droid Serif', Georgia, serif;
}
p.lefted,
.lefted p {
  text-align: left;
}
.large {
  font-size: 20px !important;
  padding: 15px 0 15px;
}
.large.no-padding {
  padding: 0;
}
.with-bg p {
  color: #fff;
}
.with-bg p.small {
  opacity: 1;
}
.info-box-content p.large,
.header-wrapper p.large {
  max-width: 474px;
  margin-left: auto;
  margin-right: auto;
}
p.below-button {
  position: relative;
  top: -10px;
  font-style: italic;
  font-size: 13px;
}
.agency p.below-button {
  top: -5px;
}
.with-bg a p.below-button {
  color: #fff;
  text-decoration: underline;
  opacity: 0.7;
}
.with-bg a:hover p.below-button {
  color: #fff;
  opacity: 1;
}
blockquote {
  position: relative;
  margin: 2em 0;
  padding: 0.4em 1.2em;
  text-align: center;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -2px;
  font-size: 34px;
  font-style: normal;
  border-left: 0;
  font-family: 'Droid Serif', Georgia, serif;
}
blockquote:before {
  display: block;
  content: "“";
  line-height: 1;
  font-size: 60px;
  position: absolute;
  left: 0;
  top: -12px;
}
blockquote cite {
  display: block;
  font-size: 12px;
  padding-right: 1em;
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  color: #c5c2b6;
  text-transform: uppercase;
  letter-spacing: 2px;
}
blockquote.align-left {
  display: block;
  margin: 1.6em auto;
  clear: both;
  float: left;
  margin: 0.6em 2em 0.5em 0;
  margin-left: -180px;
  margin-right: 2em;
  max-width: 60%;
  width: 60% !important;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 20px;
}
@media only screen and (max-width: 400px) {
  blockquote.align-left {
    float: inherit;
    margin: 30px 0 0 0;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0;
  }
}
.button {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 11px !important;
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0px;
  font-family: 'Droid Serif', Georgia, serif;
  position: relative;
  opacity: 0.6;
  color: #666 !important;
}
.button i.fa {
  margin: 0 !important;
}
.button:hover {
  opacity: 1;
}
.prev-next-wrapper {
  padding-top: 30px;
}
.prev-next-wrapper .button {
  color: #6d6d6d !important;
  font-size: 20px !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 400px) {
  .prev-next-wrapper .button {
    font-size: 15px !important;
  }
}
.prev-next-wrapper .button:hover {
  color: #414141 !important;
}
/* End Typography
--------------------------------------------------------------------------------------------------------*/
/* =4. Headings
--------------------------------------------------------------------------------------------------------*/
h1#logo {
  position: relative;
  height: 198px;
  width: 300px;
  padding: 0 !important;
  margin: 50px auto 50px auto;
  display: block;
}
h1#logo a {
  background: url("/img/logo.png") center no-repeat;
  background-size: 300px;
  width: 300px; /*try to keep it below 75px*/
  height: 128px;
  text-indent: -9999px;
  display: block;
}
@media only screen and (max-width: 400px) {
  h1#logo a {
    background-size: 100%;
    width: 100%;
  }
}
h2#small-logo {
  position: relative;
  height: 100px;
  width: 300px;
  padding: 0 !important;
  margin: 20px auto 100px auto;
  display: block;
}
h2#small-logo a {
  background: url("/img/logo.png") center no-repeat;
  background-size: 300px;
  width: 300px; /*try to keep it below 75px*/
  height: 128px;
  text-indent: -9999px;
  display: block;
}
@media only screen and (max-width: 400px) {
  h2#small-logo a {
    background-size: 100%;
    width: 100%;
  }
}
.numbers,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  color: #49505d;
  display: block;
  position: relative;
  font-family: 'Droid Serif', Georgia, serif;
  font-weight: 600;
}
.col2-3 h2,
.col2-3 h3,
.col3-3 h2 {
  padding-bottom: 15px;
}
.col2-3.half h2,
.col2-3.half h3 {
  padding-bottom: 0;
}
.with-bg h1,
.with-bg h2,
.with-bg h3,
.with-bg h4,
.with-bg h5,
.with-bg h6 {
  color: #fff;
}
h1 {
  font-size: 55px;
  line-height: 1.1;
  letter-spacing: -3px;
  margin-bottom: 20px;
}
h2 {
  font-size: 26px;
  line-height: 1.2;
  letter-spacing: -2px;
}
h2 strong {
  letter-spacing: -1px;
}
h3 {
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: -1px;
  margin-top: 10px;
}
h4 {
  font-size: 14px;
  letter-spacing: -0.5px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
.underline {
  background: linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b);
  -webkit-background: linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b);
  -o-background: linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b);
  -ms-background: linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b), linear-gradient(#2b2b2b, #2b2b2b);
  background-size: 0 0px, 0 0px, 0px 0px;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: 0 88%, 88% 88%, 0 88%;
  -webkit-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
}
.with-bg .underline {
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  -webkit-background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  -o-background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  -ms-background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-size: 0 0px, 0 0px, 0px 0px;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: 0 89%, 89% 89%, 0 89%;
  -webkit-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
}
a:hover .underline {
  background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  -webkit-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
}
h1 span.italic,
h2 span.italic,
h3 span.italic,
h4 span.italic,
h5 span.italic {
  font-family: "Lora", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: italic;
}
span.heading {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
}
.header-wrapper h2 {
  max-width: 674px;
  margin-left: auto;
  margin-right: auto;
}
.below-icon {
  margin-top: 0px;
  margin-bottom: 0px;
}
.header {
  margin-top: 0px;
}
.header2 {
  margin-top: 7px;
}
.lone-header {
  margin-top: -10px;
}
.more-margin {
  margin-top: 20px;
}
.no-margin-nor-padding {
  padding: 0;
  margin: 0;
}
.header-section h5 {
  margin-bottom: 13px;
}
.number {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Droid Serif', Georgia, serif;
  top: -2px;
  position: relative;
}
.social-header {
  margin-bottom: 0;
  padding-top: 2px;
}
.social-header i.fa {
  opacity: 0.5;
}
/* End Headings
--------------------------------------------------------------------------------------------------------*/
/* =5. Main Nav
--------------------------------------------------------------------------------------------------------*/
#main-nav {
  background-color: #263238;
  top: 0;
  right: 0;
  padding: 0px 0px;
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 11111;
}
#main-nav ul {
  position: relative;
  padding: 0px 10px;
  float: left;
}
#main-nav ul li {
  display: inline-block;
}
#main-nav ul li a {
  position: relative;
  color: rgba(255,255,255,0.85);
  padding: 15px 0;
  display: inline-block;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-right: 15px;
}
#main-nav ul li a:hover,
#main-nav ul li a.selected {
  color: #fe9300;
}
#main-nav ul li ul {
  padding: 5px 20px 8px;
  position: absolute;
  background-color: #1d1d1d;
  width: auto; /* left:0; top:0;*/
  margin-left: -20px;
}
#main-nav ul li ul li {
  display: block;
}
#main-nav ul li ul li a {
  font-size: 13px;
  border: 0;
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  display: block;
}
#main-nav ul li ul li:last-child a {
  border-bottom: 0;
}
.sub-nav-toggle {
  position: relative;
  padding-right: 15px !important;
}
.sub-nav-toggle::after {
  content: "\f107";
  font-family: FontAwesome;
  font-style: normal;
  text-decoration: inherit;
  color: rgba(255,255,255,0.6);
  font-size: 13px;
  position: absolute;
  font-weight: 600;
  top: 15px;
  right: 0;
  z-index: -1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
/* End Main Nav
--------------------------------------------------------------------------------------------------------*/
/* =6. Main Content
--------------------------------------------------------------------------------------------------------*/
#header {
  position: relative;
  z-index: 155;
  width: 100%;
  padding: 83px 45px 23px;
  -webkit-transform: translateZ(0);
  background: url("/img/header.jpg") no-repeat center center;
  background-size: cover;
}
#header:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 404px;
  width: calc(100% + 90px);
  background: transparent url("/img/pattern.png") repeat 0 0;
}
@media only screen and (max-width: 400px) {
  #header:before {
    min-height: 360px;
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  #header {
    padding: 40px 0px 23px;
  }
}
#content {
  width: 100%;
  position: relative;
  z-index: 55;
  height: auto;
  background: #fff;
  padding-top: 40px;
}
section.white {
  background: #fff;
}
.container {
  position: relative;
  margin: 0 auto;
  padding: 0;
}
#container {
  position: relative;
  margin: 0 auto;
  padding-bottom: 40px;
}
.at-bottom {
  padding-bottom: 80px !important;
}
.pushed-left #wrap,
.pushed-left #footer {
  -webkit-transform: translate3d(-200px, 0px, 0px);
  transform: translate3d(-200px, 0px, 0px);
}
.pushed-left-alt #wrap,
.no-csstransforms3d .pushed-left #wrap,
.pushed-left-alt #footer,
.no-csstransforms3d .pushed-left #footer {
  -webkit-transform: translateX(-200px);
  -ms-transform: translateX(-200px);
  transform: translateX(-200px);
}
#wrap,
#footer {
  -webkit-transition: -webkit-transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
.bottom-section {
  margin-top: 15px;
}
.header-section {
  margin-bottom: 45px;
  border-bottom: 1px dotted #d8d8d8;
  padding-bottom: 15px;
}
/* =End Main Content
--------------------------------------------------------------------------------------------------------*/
/* =7. Column Grid
--------------------------------------------------------------------------------------------------------*/
.col--1-1 {
  width: 234px;
  height: 234px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--2-2 {
  width: 468px;
  height: 468px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--1-2 {
  width: 234px;
  height: 468px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--2-1 {
  width: 468px;
  height: 234px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--1-3 {
  width: 390px;
  height: 390px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--2-3 {
  width: 468px;
  height: 702px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--3-2 {
  width: 702px;
  height: 468px;
  margin: 0px;
  float: left;
  position: relative;
  padding: 10px;
}
.col--5-5 {
  width: 100%;
  height: auto;
  margin: 0px;
  float: none;
  position: relative;
  padding: 10px;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding: 10px;
}
.vertical-aligned-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
.borders {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 5px;
  width: 100%;
  height: 100%;
  position: relative;
}
.with-bg .borders {
  border: 0;
}
.borders.no-image {
  border-top: 2px solid #2b2b2b;
  height: 214px;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.borders.right-image {
  border: 0;
}
.borders.complete {
  border: 1px solid #d8d8d8 !important; /*background:rgba(0,0,0,0.03);*/
}
.borders.complete.up-top {
  background: none;
  position: relative;
  overflow: visible;
}
.borders.complete.up-top p.small {
  position: absolute;
  top: -6px;
  left: 0;
  text-align: center;
  padding: 0 20px !important;
  margin: 0 !important;
  width: 100%;
  opacity: 1;
}
.borders.complete.up-top p.small span {
  display: inline-block;
  background: #fff;
  padding: 0 15px;
  z-index: 111;
}
.borders .big-header {
  padding: 55px 80px;
}
@media only screen and (max-width: 400px) {
  .borders .big-header {
    padding: 85px 20px;
  }
}
.post-image .borders.big-header {
  padding: 73px 130px 50px;
}
@media only screen and (max-width: 400px) {
  .post-image .borders.big-header {
    padding: 85px 10px !important;
  }
}
.borders.no-borders {
  border: 0 !important;
}
.image-holder {
  height: 468px;
  width: 300px;
  position: relative;
  float: left;
}
.text-holder {
  padding: 0px 10px 0px 0px;
  height: 448px;
  width: 362px;
  margin-left: 320px;
  border-top: 2px solid #2b2b2b;
  border-bottom: 1px solid #d8d8d8;
}
.col--2-1 .image-holder {
  height: 234px;
  width: 140px;
}
.col--2-1 .text-holder {
  height: 214px;
  width: 288px;
  margin-left: 160px;
}
.extra-padding-right {
  padding-right: 30px;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}
body.centered-tiles .element {
  text-align: center;
}
.with-bg {
  -webkit-text-shadow: 0px 0 30px rgba(0,0,0,0.2);
  text-shadow: 0px 0 30px rgba(0,0,0,0.2);
  text-align: center;
  position: relative;
}
.full-height-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.containing-image-wrapper {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
/* =End Column Grid
--------------------------------------------------------------------------------------------------------*/
/* =8. Content Elements
--------------------------------------------------------------------------------------------------------*/
.overflow,
.element,
.borders {
  overflow: hidden;
}
.extra-padding-top {
  padding-top: 10px;
}
.break {
  display: block;
  position: relative;
  height: 20px;
  width: 100%;
}
.borderline {
  border-bottom: 1px solid #d8d8d8;
  height: 1px;
  position: relative;
  width: 100%;
  margin: 30px 0 3px;
}
.borderline.another {
  margin-top: 0px;
}
.background-image1 {
  background: url("../images/fullscreen13.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image2 {
  background: url("../images/project11.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image3 {
  background: url("../images/fullscreen23.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image4 {
  background: url("../images/fullscreen08.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image5 {
  background: url("../images/fullscreen06.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image6 {
  background: url("../images/pic22.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image7 {
  background: url("../images/food01.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image8 {
  background: url("../images/travel01.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image9 {
  background: url("../images/lifestyle01.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image10 {
  background: url("../images/fullscreen12.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image11 {
  background: url("/img/bg/cartespostales.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.background-image12 {
  background: url("../images/lifestyle05.jpg") no-repeat center;
  height: 100%;
  display: block;
  width: 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
img.alignleft {
  width: 50%;
}
/* =End Content Elements
--------------------------------------------------------------------------------------------------------*/
/* =9. Icons
--------------------------------------------------------------------------------------------------------*/
.social-list {
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0;
  font-size: 0;
}
.social-list li {
  display: inline-block;
  padding: 0 3px;
  position: relative;
}
.social-list li a i {
  color: #888;
  font-size: 17px;
}
.social-list li a:hover i {
  color: #2b2b2b;
}
.social-list.aligncenter {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  top: 18px;
  margin-left: 10px !important;
}
nav .social-list {
  position: relative;
  float: right !important;
  width: auto;
  text-align: left;
}
nav .social-list li {
  padding: 0px 0px 0 0;
  display: inline-block;
}
nav .social-list li a {
  border: 0 !important;
  margin: 0 !important;
  margin-left: 15px !important;
}
nav .social-list li a i,
#footer .social-list li a i {
  color: #fff;
  margin: 0 !important;
}
nav .social-list li a:hover i,
#footer .social-list li a:hover i {
  color: rgba(255,255,255,0.5);
}
nav .social-list a.search-button {
  font-size: 13px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background: rgba(0,0,0,0.05);
}
.social-list.alignright li {
  padding: 0 0 0 10px;
}
.social-icon {
  font-size: 44px;
  line-height: 1.2;
}
i.fa {
  margin-right: 3px;
  color: #2b2b2b;
}
p i.fa {
  color: #888;
}
.button i.fa {
  color: #2b2b2b;
  font-size: 11px !important;
}
.with-bg i.fa {
  color: #fff !important;
}
/* End Icons
--------------------------------------------------------------------------------------------------------*/
/* =10. Image Hover
--------------------------------------------------------------------------------------------------------*/
.images {
  display: block;
  position: relative;
}
article .images {
  margin: 40px 0;
}
img {
  width: 100%;
  display: block;
  max-width: 100%;
  z-index: 9;
  box-shadow: #fff 0 0 0;
  -webkit-box-shadow: #fff 0 0 0;
}
.round img {
  border: 0 none;
  border-radius: 50%;
}
.videos {
  width: 1140px;
  height: 650px;
  border: 0;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 35px 0 20px;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.musicWrapper {
  position: relative;
  height: auto;
  margin-bottom: -6px;
  padding: 20px 0;
}
.musicWrapper iframe {
  width: 100%;
  height: auto;
}
.overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url("/img/pattern.png") repeat;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dark-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url("/img/pattern-error.png") repeat;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  opacity: 0.4;
}
.info-box-content {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.opacity-100 {
  opacity: 1 !important;
}
/* End Image Hover
--------------------------------------------------------------------------------------------------------*/
/* =11. Compatibility Rules
--------------------------------------------------------------------------------------------------------*/
.lt-ie9 .pushed-left #wrap,
.lt-ie9 .pushed-left #footer,
.lt-ie9 .pushed-left #background-color {
  position: relative;
  margin-left: -200px;
}
.lt-ie9 #header {
  min-width: 960px;
  padding-left: 10px;
  padding-right: 10px;
}
.lt-ie9 body,
.lt-ie9 .content-wrapper {
  min-width: 960px;
}
.lt-ie9 .pushed-left #main-nav {
  z-index: 200;
}
/* =End Compatibility Rules
--------------------------------------------------------------------------------------------------------*/
/* =12. Footer
--------------------------------------------------------------------------------------------------------*/
#footer {
  background: #424854;
  margin: 0px;
  width: 100%;
  z-index: 1;
  position: relative;
  webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);
}
#footer .container {
  padding: 31px 10px 29px;
}
footer p {
  color: rgba(255,255,255,0.6);
  font-size: 13px;
  padding: 0;
  border: 0;
  text-decoration: none;
}
footer p a {
  color: rgba(255,255,255,0.6);
}
footer p a:hover {
  color: #fff;
}
span.padding {
  padding: 0 3px;
}
footer i.fa-heart {
  color: #f4645f;
}
#footer h4 {
  color: #fff;
  font-size: 20px;
}
/* End Footer
--------------------------------------------------------------------------------------------------------*/
/* =13. Lists
--------------------------------------------------------------------------------------------------------*/
ul.unordered-list,
.hentry ul,
ol {
  list-style: disc;
  line-height: 1.5;
  padding: 10px 0px 10px 30px;
  font-size: 16px;
  margin-bottom: 0;
}
ol {
  list-style: decimal;
}
.bottom-section ul.unordered-list,
.bottom-section ol {
  padding: 0px;
  padding-top: 10px;
  list-style: none;
}
ul.unordered-list li,
ol li {
  padding-bottom: 7px;
}
ul.unordered-list li:last-child,
ol li:last-child {
  padding-bottom: 0px;
}
ul.unordered-list li a,
.hentry ul li a,
ol li a {
  color: #555;
  text-decoration: underline;
}
ul.unordered-list li a:hover,
.hentry ul li a:hover,
ol li a:hover {
  color: #2b2b2b;
  text-decoration: underline;
}
.with-bg ul,
.with-bg ol {
  color: #fff;
}
.centered-tiles ul.unordered-list,
.centered-tiles ol {
  padding-left: 11px;
}
ul.pagination {
  margin-bottom: 60px;
  border-radius: 0;
  margin-top: 0;
}
ul.pagination li a {
  font-family: 'Droid Serif', Georgia, serif;
  margin: 0 3px;
  color: #999;
  background: none;
  border-radius: 0 !important;
  border: 1px solid #d8d8d8;
}
ul.pagination li a:hover {
  color: #2b2b2b;
}
ul.pagination li a.active {
  border: 2px solid #2b2b2b;
  color: #2b2b2b;
  padding: 5px 11px;
}
ul.pagination li a.active:hover {
  background: none !important;
  cursor: default;
}
/* =End Lists
--------------------------------------------------------------------------------------------------------*/
/* =14. Blog
--------------------------------------------------------------------------------------------------------*/
.signature {
  background: url("../images/signature.png") center no-repeat;
  background-size: 185px 50px;
  width: 185px;
  height: 50px;
  position: relative;
  display: inline-block;
  opacity: 0.85;
}
.avatar {
  display: inline-block;
  margin: 10px 0 10px;
  float: left;
}
.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 51%;
}
.avatar.larger {
  float: none;
  margin-top: 20px;
}
.avatar.author {
  margin-top: 20px;
}
.avatar.larger img,
.avatar.author img {
  width: 94px;
  height: 94px;
}
.element .avatar img {
  width: 40px;
  height: 40px;
}
.avatar-holder {
  position: absolute;
  bottom: 10px;
}
.post .avatar-holder {
  position: relative;
  bottom: auto;
  float: left;
}
.left-to-avatar {
  text-align: left !important;
  padding-left: 120px;
  padding-top: 5px;
}
.element .left-to-avatar {
  padding-left: 55px;
  padding-top: 11px;
}
.post .left-to-avatar {
  padding-left: 120px;
  padding-top: 5px;
}
aside {
  margin-bottom: 40px;
}
aside.with-bg .borders {
  margin-bottom: 20px;
}
aside .borders {
  padding: 3px;
}
.second-borders {
  border: 1px solid #d8d8d8;
  padding: 17px 35px;
}
aside .latest-posts {
  margin-bottom: 20px;
  display: block;
}
.post-image {
  margin-bottom: 10px;
}
.post-text {
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid rgba(216,216,216,0.75);
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: block;
}
.post-text.no-top-border {
  border-top: 0;
  margin-top: 0;
}
.post-text p,
.post-text h3 {
  padding: 10px 0 0px;
}
.post-header {
  padding: 25px 0 0px;
  text-align: left;
}
.comment {
  padding: 40px 0 35px 0;
  border-top: 1px solid #d8d8d8;
  position: relative;
  text-align: left;
}
.comment.reply.level-1 {
  padding-left: 6.6%;
}
.comment.last {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 60px;
}
.commenter-avatar {
  float: left;
  padding: 0 30px 30px 0;
  width: auto;
}
.commenter-avatar > img {
  width: 65px;
  height: 65px;
  border-radius: 51%;
}
.comment-content {
  float: left;
  width: calc(100% - 95px);
}
.comment-content a {
  text-decoration: none !important;
}
.comment-form-wrapper {
  padding: 20px 0 60px 0;
  position: relative;
}
.comment-form-icon-wrapper {
  position: relative;
  display: block;
  height: 66px;
  width: 66px;
  border: 0 none;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  border-radius: 51%;
  float: left;
  margin-right: 30px;
  background: url("../images/icons/bg-upload@2x.png") center no-repeat;
  background-size: 24px 24px;
}
.comment-form {
  text-align: left;
  padding-left: 95px;
  width: 100%;
}
.contact .comment-form {
  padding-left: 0;
}
.comment-form > input,
.comment-form > textarea {
  padding: 12px 15px 14px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
  text-align: left;
  line-height: 20px;
  width: calc(100% - 1px);
}
.contact .comment-form > input,
.contact .comment-form > textarea {
  width: 100%;
}
input:focus,
textarea:focus {
  background: rgba(0,0,0,0.03) !important;
}
.comment-form.contact-form input {
  float: left;
  margin-right: 10px;
}
input.last {
  margin-right: 0 !important;
}
.comment-form > textarea {
  width: calc(100% - 1px);
  height: 174px;
}
.comment-form .submit-btn {
  opacity: 0.6;
  font-size: 11px;
  font-family: 'Droid Serif', Georgia, serif;
}
.comment-form .submit-btn:hover {
  opacity: 1;
}
.comment-form .input-wrapper {
  position: relative;
  padding-left: 33px;
  margin-top: 20px;
}
.comment-form .input-wrapper::after {
  content: "\f1d8";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size: 11px;
  position: absolute;
  opacity: 0.6;
  top: 0;
  left: 16px;
  height: 24px;
  text-align: left;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.btn-absolute {
  margin-top: 20px;
  font-size: 130%;
  background: #fff;
  position: absolute;
  min-width: 190px;
  text-align: center;
  margin-left: -95px;
}
.margin-top-negatif-50 {
  margin-top: -50px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.align-center {
  text-align: center;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-28 {
  font-size: 28px !important;
}
/* End Blog
--------------------------------------------------------------------------------------------------------*/
h2.section-title {
  border-top: 1px solid #e2e2e2;
  color: #5d5d5d;
  font-size: 17px;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 10px;
  position: relative;
  letter-spacing: 2px;
}
h2.section-title span {
  display: inline-block;
  background-color: #fff;
  padding: 0px 12px;
  top: -12px;
  position: relative;
}
h2.section-title span.grey {
  background: #f2f2f2;
}
.social-sharing-box {
  background: #f2f2f2;
  padding: 20px 20px 15px;
  min-height: 150px;
}
.social-sharing-box .btn {
  background: #fff;
  text-decoration: none;
}
.social-sharing-box h2.section-title {
  color: #5d5d5d;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  letter-spacing: 2px;
}
.social-sharing-box h2.section-title span {
  background: #f2f2f2;
}
.instagram-wrap {
  background: #49505d;
}
.instagram-wrap h4 {
  margin-top: 25px;
  color: #aeb1b6;
}
.instagram {
  width: calc(99.9% * 4/4 - (0px - 0px * 4/4));
}
.instagram:nth-child(1n) {
  float: left;
  margin-right: 0px;
  clear: none;
}
.instagram:last-child {
  margin-right: 0;
}
.instagram:nth-child(1n) {
  margin-right: 0;
  float: right;
}
.instagram:nth-child(1n + 1) {
  clear: both;
}
.instagram div {
  width: calc(99.9% * 1/4 - (10px - 10px * 1/4));
}
.instagram div:nth-child(1n) {
  float: left;
  margin-right: 10px;
  clear: none;
}
.instagram div:last-child {
  margin-right: 0;
}
.instagram div:nth-child(4n) {
  margin-right: 0;
  float: right;
}
.instagram div:nth-child(4n + 1) {
  clear: both;
}
.instagram div img {
  width: 100%;
  float: left;
  vertical-align: top;
  margin-bottom: 10px;
}
figure {
  background: #ececec;
  text-align: center;
  font-size: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
}
figure img {
  margin-bottom: 10px;
}
.image {
  margin-bottom: 10px;
  width: calc(99.9% * 1/2 - (10px - 10px * 1/2));
}
.image:nth-child(1n) {
  float: left;
  margin-right: 10px;
  clear: none;
}
.image:last-child {
  margin-right: 0;
}
.image:nth-child(2n) {
  margin-right: 0;
  float: right;
}
.image:nth-child(2n + 1) {
  clear: both;
}
.image img {
  vertical-align: top;
  width: 100%;
}
.no-border-bottom {
  border-bottom: none;
}
.post-images {
  width: calc(99.9% * 1/1);
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #d8d8d8;
}
.post-images:nth-child(1n) {
  float: left;
  margin-right: 0;
  clear: none;
}
.post-images:last-child {
  margin-right: 0;
}
.post-images:nth-child(1n) {
  margin-right: 0;
  float: right;
}
.post-images:nth-child(1n + 1) {
  clear: both;
}
h1.live-feed {
  color: #49505d;
  font-size: 48px;
  text-align: center;
  width: 700px;
  display: block;
  margin: 30px auto 30px auto;
}
@media only screen and (max-width: 400px) {
  h1.live-feed {
    width: 100%;
    font-size: 28px !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  h1.live-feed {
    width: 100%;
    font-size: 35px;
  }
}
h1.live-feed a {
  color: #de9451;
}
h1.live-feed span {
  margin-top: 0;
}
h1.live-feed span:nth-of-type(1) {
  font-size: 30px;
  display: block;
  margin: 10px auto 6px auto;
  text-transform: none;
}
@media only screen and (max-width: 400px) {
  h1.live-feed span:nth-of-type(1) {
    font-size: 24px !important;
  }
}
.etapes-row {
  width: calc(99.9% * 1/1);
}
.etapes-row:nth-child(1n) {
  float: left;
  margin-right: 0;
  clear: none;
}
.etapes-row:last-child {
  margin-right: 0;
}
.etapes-row:nth-child(1n) {
  margin-right: 0;
  float: right;
}
.etapes-row:nth-child(1n + 1) {
  clear: both;
}
@media only screen and (max-width: 400px) {
  .etapes-row {
    width: calc(99.9% * 1/1 - (20px - 20px * 1/1));
    padding: 0 25px;
  }
  .etapes-row:nth-child(1n) {
    float: left;
    margin-right: 20px;
    clear: none;
  }
  .etapes-row:last-child {
    margin-right: 0;
  }
  .etapes-row:nth-child(1n) {
    margin-right: 0;
    float: right;
  }
  .etapes-row:nth-child(1n + 1) {
    clear: both;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .etapes-row {
    width: calc(99.9% * 1/1 - (20px - 20px * 1/1));
    padding: 0 25px;
  }
  .etapes-row:nth-child(1n) {
    float: left;
    margin-right: 20px;
    clear: none;
  }
  .etapes-row:last-child {
    margin-right: 0;
  }
  .etapes-row:nth-child(1n) {
    margin-right: 0;
    float: right;
  }
  .etapes-row:nth-child(1n + 1) {
    clear: both;
  }
}
.etapes-row .clear-after-3 {
  width: calc(99.9% * 1/3 - (20px - 20px * 1/3));
  margin-bottom: 40px;
}
.etapes-row .clear-after-3:nth-child(1n) {
  float: left;
  margin-right: 20px;
  clear: none;
}
.etapes-row .clear-after-3:last-child {
  margin-right: 0;
}
.etapes-row .clear-after-3:nth-child(3n) {
  margin-right: 0;
  float: right;
}
.etapes-row .clear-after-3:nth-child(3n + 1) {
  clear: both;
}
@media only screen and (max-width: 400px) {
  .etapes-row .clear-after-3 {
    width: calc(99.9% * 3/3 - (20px - 20px * 3/3));
  }
  .etapes-row .clear-after-3:nth-child(1n) {
    float: left;
    margin-right: 20px;
    clear: none;
  }
  .etapes-row .clear-after-3:last-child {
    margin-right: 0;
  }
  .etapes-row .clear-after-3:nth-child(3n) {
    margin-right: 0;
    float: right;
  }
  .etapes-row .clear-after-3:nth-child(3n + 1) {
    clear: both;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .etapes-row .clear-after-2 {
    width: calc(99.9% * 1/2 - (20px - 20px * 1/2));
  }
  .etapes-row .clear-after-2:nth-child(1n) {
    float: left;
    margin-right: 20px;
    clear: none;
  }
  .etapes-row .clear-after-2:last-child {
    margin-right: 0;
  }
  .etapes-row .clear-after-2:nth-child(2n) {
    margin-right: 0;
    float: right;
  }
  .etapes-row .clear-after-2:nth-child(2n + 1) {
    clear: both;
  }
}
.no-padding-left {
  padding-left: 0;
}
.btn-cartes {
  border-radius: 0;
  width: 200px !important;
  text-align: center !important;
}
.search-form form {
  margin-bottom: 30px;
}
.search-form form img {
  max-width: 80px;
  float: right;
}
.search-link {
  color: #170c9a;
}
.search-form {
  margin-top: 10px;
  margin-bottom: 30px;
  min-height: 400px;
}
.hr-line-dashed {
  border-top: 1px dashed #e7eaec;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin: 20px 0;
}
.search-result h3 {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 20px;
}
.search-result h3 a {
  color: #49505d;
}
.search-result-params {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: solid 1px #cadeea;
}
.search-table-result tbody tr td {
  padding: 5px 4px;
}
.search-table-result tbody tr:nth-child(odd) td {
  background-color: rgba(202,222,234,0.65);
}
.search-table-result tbody tr:nth-child(even) td {
  background-color: rgba(255,255,255,0.25);
}
.light-hr {
  border-color: rgba(255,255,255,0.4);
}
.margin-top-40 {
  margin-top: 40px;
}
.padding-bottom-top-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
@media only screen and (max-width: 400px) {
  .padding-bottom-top-100 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
}
.padding-right-50 {
  padding-right: 50px;
}
@media only screen and (max-width: 400px) {
  .padding-right-50 {
    padding-right: 0;
  }
}
@media only screen and (max-width: 400px) {
  footer p {
    text-align: left !important;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 400px) {
  footer .widget {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}
.video-container {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.no-border-top {
  border-top: none;
  padding-top: 0;
}
ul.tags {
  margin: 0;
  padding: 0;
}
ul.tags li {
  display: inline;
  font-size: 10px;
  letter-spacing: 1px;
  opacity: 0.9;
  text-transform: uppercase;
}
ul.tags li:after {
  content: ", ";
}
ul.tags li:last-child:after {
  content: "";
}
@media only screen and (max-width: 400px) {
  .left-to-avatar {
    padding-left: 0;
  }
}
.left-to-avatar a {
  color: #ef8101;
}
.left-to-avatar h4 a {
  color: #49505d;
}
