/* box-sizing */
.fr-window, .fr-window [class^="fr-"],
.fr-overlay, .fr-overlay [class^="fr-"],
.fr-spinner, .fr-spinner [class^="fr-"]
    box-sizing: border-box


.fr-window
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    font: 13px/20px "Lucida Sans", "Lucida Sans Unicode", "Lucida Grande", Verdana, Arial, sans-serif
    transform: translateZ(0px)


/* z-index */
.fr-overlay
    z-index:  99998

.fr-window
    z-index:  99999

.fr-spinner
    z-index: 100000



/* overlay */
.fr-overlay
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%

.fr-overlay-background
    float: left
    width: 100%
    height: 100%
    background: #000
    opacity: .9

.fr-overlay-ui-fullclick .fr-overlay-background
    background: #292929
    opacity: 1


/* mobile touch has position:absolute to allow zooming */
.fr-window.fr-mobile-touch,
.fr-overlay.fr-mobile-touch
    position: absolute
    overflow: visible


/* some properties on the window are used to toggle things
 * like margin and the fullclick mode,
 * we reset those properties after measuring them
 */
.fr-measured
    margin: 0 !important
    min-width: 0 !important
    min-height: 0 !important


.fr-box
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%


.fr-pages
    position: absolute
    width: 100%
    height: 100%
    overflow: hidden
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none


.fr-page
    position: absolute
    width: 100%
    height: 100%



.fr-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0
    padding: 0
    text-align: center


.fr-hovering-clickable .fr-container
    cursor: pointer


/*
   padding:
   youtube & vimeo always have buttons on the outside
   side button = 72 = 54 + (2 * 9 margin)
   close button = 48
*/
/* padding ui:inside */
.fr-ui-inside .fr-container
    padding: 20px 20px

.fr-ui-inside.fr-no-sides .fr-container
    padding: 20px


/* padding ui:outside */
.fr-ui-outside .fr-container
    padding: 20px 82px

.fr-ui-outside.fr-no-sides .fr-container
    padding: 20px 48px


/* reduce padding on smaller screens */
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 700px)
    /* padding ui:inside */
    .fr-ui-inside .fr-container
        padding: 12px 12px

    .fr-ui-inside.fr-no-sides .fr-container
        padding: 12px


    /* padding ui:outside */
    .fr-ui-outside .fr-container
        padding: 12px 72px

    .fr-ui-outside.fr-no-sides .fr-container
        padding: 12px 48px

@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px)
    .fr-ui-inside .fr-container
        padding: 0px 0px

    .fr-ui-inside.fr-no-sides .fr-container
        padding: 0px

    /* padding ui:outside */
    .fr-ui-outside .fr-container
        padding: 0px 72px

    .fr-ui-outside.fr-no-sides .fr-container
        padding: 0px 48px

/* padding ui:fullclick */
.fr-ui-fullclick .fr-container
    padding: 0

.fr-ui-fullclick.fr-no-sides .fr-container
    padding: 0

/* video fullclick */
.fr-ui-fullclick.fr-type-video .fr-container
    padding: 0px 62px

.fr-ui-fullclick.fr-no-sides.fr-type-video .fr-container
    padding: 48px 0px

/* overflow-y should always have 0 padding top and bottom */
.fr-overflow-y .fr-container
    padding-top: 0 !important
    padding-bottom: 0!important


.fr-content,
.fr-content-background
    position: absolute
    top: 50%
    left: 50%
    overflow: visible


/* img/iframe/error */
.fr-content-element
    float: left
    width: 100%
    height: 100%

.fr-content-background
    background: #101010

.fr-info
    position: absolute
    top: 0
    left: 0
    width: 100%
    color: #efefef
    font-size: 13px
    line-height: 20px
    text-align: left
    -webkit-text-size-adjust: none
    text-size-adjust: none
    -webkit-user-select: text
    -khtml-user-select: text
    -moz-user-select: text
    -ms-user-select: text
    user-select: text

.fr-info-background
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background: #000
    line-height: 1%
    filter: none
    opacity: 1

.fr-ui-inside .fr-info-background
    opacity: .8
    zoom: 1

/* ui:outside has a slightly lighter info background to break up from black page backgrounds */
.fr-ui-outside .fr-info-background
    background: #0d0d0d


/* info at the bottom for ui:inside */
.fr-content .fr-info
    top: auto
    bottom: 0

.fr-info-padder
    display: block
    overflow: hidden
    padding: 12px
    position: relative
    width: auto

.fr-caption
    width: auto
    display: inline
    white-space: wrap

.fr-position
    color: #b3b3b3
    float: right
    line-height: 21px
    opacity: 0.99
    position: relative
    text-align: right
    margin-left: 15px
    white-space: nowrap

/* positions not within the info bar
    for ui:outside/inside */
.fr-position-outside,
.fr-position-inside
    position: absolute
    bottom: 0
    right: 0
    margin: 12px
    width: auto
    padding: 2px 8px
    border-radius: 10px
    font-size: 11px
    line-height: 20px
    text-shadow: 0 1px 0 rgba(0,0,0,.4)
    display: none
    overflow: hidden
    white-space: nowrap
    -webkit-text-size-adjust: none
    text-size-adjust: none
    -webkit-user-select: text
    -khtml-user-select: text
    -moz-user-select: text
    -ms-user-select: text
    user-select: text

.fr-position-inside
    border: 0

.fr-position-background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: #0d0d0d
    opacity: .8
    zoom: 1 /* oldIE */

.fr-position-text
    color: #b3b3b3

.fr-position-outside .fr-position-text,
.fr-position-inside .fr-position-text
    float: left
    position: relative
    text-shadow: 0 1px 1px rgba(0,0,0,.3)
    opacity: 1

/* display it for the outside ui, hide the caption inside */
.fr-ui-outside .fr-position-outside
    display: block

.fr-ui-outside .fr-info .fr-position
    display: none

.fr-ui-inside.fr-no-caption .fr-position-inside
    display: block

/* links */
.fr-info a,
.fr-info a:hover
    color: #ccc
    border: 0
    background: none
    text-decoration: underline

.fr-info a:hover
    color: #eee

/* no-caption */
.fr-ui-outside.fr-no-caption .fr-info
    display: none

.fr-ui-inside.fr-no-caption .fr-caption
    display: none

/* --- stroke */
.fr-stroke
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 1px

.fr-stroke-vertical
    width: 1px
    height: 100%

.fr-stroke-horizontal
    padding: 0 1px

.fr-stroke-right
    left: auto
    right: 0

.fr-stroke-bottom
    top: auto
    bottom: 0

.fr-stroke-color
    float: left
    width: 100%
    height: 100%
    background: rgba(255,255,255,.08)

/* the stroke is brighter inside the info box */
.fr-info .fr-stroke-color
    background: rgba(80,80,80,.3)

/* remove the stroke when outside ui has a caption */
.fr-ui-outside.fr-has-caption .fr-content .fr-stroke-bottom
    display: none

/* remove stroke and shadow when ui:fullclick */
.fr-ui-fullclick .fr-stroke
    display: none

.fr-ui-fullclick .fr-content-background
    box-shadow: none

/* the info box never bas a top stroke */
.fr-info .fr-stroke-top
    display: none


/* < > */
.fr-side
    position: absolute
    top: 50%
    width: 54px
    height: 72px
    margin: 0 9px
    margin-top: -36px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    user-select: none
    zoom: 1

.fr-side-previous
    left: 0

.fr-side-next
    right: 0
    left: auto


.fr-side-disabled
    cursor: default

.fr-side-hidden
    display: none!important


.fr-side-button
    float: left
    width: 100%
    height: 100%
    margin: 0
    padding: 0

.fr-side-button-background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #101010
    background-color: #ccc

.fr-side-button-icon
    float: left
    position: relative
    height: 100%
    width: 100%
    zoom: 1
    background-position: 50% 50%
    background-repeat: no-repeat

/* smaller side buttons */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px)
    .fr-side
        width: 54px
        height: 60px
        margin: 0
        margin-top: -30px

    .fr-side-button
        width: 48px
        height: 60px
        margin: 0 3px


/* X */
.fr-close
    position: absolute
    width: 48px
    height: 48px
    top: 0
    right: 0
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    user-select: none

.fr-close-background,
.fr-close-icon
    position: absolute
    top: 12px
    left: 12px
    height: 26px
    width: 26px
    background-position: 50% 50%
    background-repeat: no-repeat

.fr-close-background
    background-color: #000




/* Thumbnails */
.fr-thumbnails
    position: absolute
    overflow: hidden

.fr-thumbnails-disabled .fr-thumbnails
    display: none !important

.fr-thumbnails-horizontal .fr-thumbnails
    width: 100%
    height: 12%
    min-height: 74px
    max-height: 160px
    bottom: 0

.fr-thumbnails-vertical .fr-thumbnails
    height: 100%
    width: 10%
    min-width: 74px
    max-width: 160px
    left: 0

.fr-thumbnails,
.fr-thumbnails *
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.fr-thumbnails-wrapper
    position: absolute
    top: 0
    left: 50%
    height: 100%
.fr-thumbnails-vertical .fr-thumbnails-wrapper
    top: 50%
    left: 0

.fr-thumbnails-slider
    position: relative
    width: 100%
    height: 100%
    float: left
    zoom: 1

.fr-thumbnails-slider-slide
    position: absolute
    top: 0
    left: 0
    height: 100%

.fr-thumbnails-thumbs
    float: left
    height: 100%
    overflow: hidden
    position: relative
    top: 0
    left: 0

.fr-thumbnails-slide
    position: absolute
    top: 0
    height: 100%
    width: 100%

.fr-thumbnail-frame
    position: absolute
    zoom: 1
    overflow: hidden

.fr-thumbnail
    position: absolute
    width: 30px
    height: 100%
    left: 50%
    top: 50%
    zoom: 1
    cursor: pointer
    margin: 0 10px

.fr-ltIE9 .fr-thumbnail *
    overflow: hidden /* IE6(7) */
    z-index: 1
    zoom: 1

.fr-thumbnail-wrapper
    position: relative
    background: #161616
    width: 100%
    height: 100%
    float: left
    overflow: hidden
    display: inline /* IE6(7) */
    z-index: 0 /* IE8 */


.fr-thumbnail-overlay
    cursor: pointer
.fr-thumbnail-active .fr-thumbnail-overlay
    cursor: default

.fr-thumbnail-overlay,
.fr-thumbnail-overlay-background,
.fr-thumbnail-overlay-border
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border-width: 0
    overflow: hidden
    border-style: solid
    border-color: transparent

.fr-ltIE9 .fr-thumbnail-overlay-border
    border-width: 0 !important

.fr-thumbnail .fr-thumbnail-image
    position: absolute
    opacity: .85
    max-width: none

.fr-thumbnail:hover .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image
    opacity: .99

.fr-thumbnail-active .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image
    opacity: .35

.fr-thumbnail-active
    cursor: default

/* Thumbnails loading */
.fr-thumbnail-loading,
.fr-thumbnail-loading-background,
.fr-thumbnail-loading-icon
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.fr-thumbnail-loading-background
    background-color: #161616
    background-position: 50% 50%
    background-repeat: no-repeat
    opacity: .8
    position: relative
    float: left

/* this element is there as an alternative to putting the loading image on the background */
.fr-thumbnail-loading-icon
    display: none

/* error on thumbnail */
.fr-thumbnail-error .fr-thumbnail-image
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #202020

/* Thumbnail < > */
.fr-thumbnails-side
    float: left
    height: 100%
    width: 28px
    margin: 0 5px
    position: relative
    overflow: hidden

.fr-thumbnails-side-previous
    margin-left: 12px
.fr-thumbnails-side-next
    margin-right: 12px

.fr-thumbnails-vertical .fr-thumbnails-side
    height: 28px
    width: 100%
    margin: 10px 0

.fr-thumbnails-vertical .fr-thumbnails-side-previous
    margin-top: 20px

.fr-thumbnails-vertical .fr-thumbnails-side-next
    margin-bottom: 20px

.fr-thumbnails-side-button
    position: absolute
    top: 50%
    left: 50%
    margin-top: -14px
    margin-left: -14px
    width: 28px
    height: 28px
    cursor: pointer

.fr-thumbnails-side-button-background
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    opacity: .8
    -moz-transition: background-color .2s ease-in
    -webkit-transition: background-color .2s ease-in
    transition: background-color .2s ease-in
    background-color: #333
    cursor: pointer
    border-radius: 4px

.fr-thumbnails-side-button:hover .fr-thumbnails-side-button-background
    background-color: #3b3b3b


.fr-thumbnails-side-button-disabled *
    cursor: default

.fr-thumbnails-side-button-disabled:hover .fr-thumbnails-side-button-background
    background-color: #333


.fr-thumbnails-side-button-icon
    height: 42px
    width: 42px
    position: absolute
    /*top: -7px
    left: -7px*/
    top: 0
    left: 0
    width: 100%
    height: 100%
    

/* vertical thumbnails */
.fr-thumbnails-vertical .fr-thumbnails-side,
.fr-thumbnails-vertical .fr-thumbnails-thumbs,
.fr-thumbnails-vertical .fr-thumbnail-frame
    clear: both

/* ui:fullclick has a dark thumbnail background */
.fr-window-ui-fullclick .fr-thumbnails
    background: #090909


/* put a stroke on the connecting caption*/
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-horizontal .fr-info .fr-stroke-bottom
    display: block !important

.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-vertical .fr-info .fr-stroke-left
    display: block !important


/* thumbnail spacing  */
.fr-thumbnails-horizontal .fr-thumbnails-thumbs
    padding: 12px 5px

.fr-thumbnails-vertical .fr-thumbnails-thumbs
    padding: 5px 12px

.fr-thumbnails-measured .fr-thumbnails-thumbs
    padding: 0 !important

/* horizontal */
@media all and (min-height: 700px)
    .fr-thumbnails-horizontal .fr-thumbnails-thumbs
        padding: 16px 8px

    .fr-thumbnails-horizontal .fr-thumbnails-side
        margin: 0 8px

    .fr-thumbnails-horizontal .fr-thumbnails-side-previous
        margin-left: 16px

    .fr-thumbnails-horizontal .fr-thumbnails-side-next
        margin-right: 16px

@media all and (min-height: 980px)
    .fr-thumbnails-horizontal .fr-thumbnails-thumbs
        padding: 20px 10px

    .fr-thumbnails-horizontal .fr-thumbnails-side
        margin: 0 10px

    .fr-thumbnails-horizontal .fr-thumbnails-side-previous
        margin-left: 20px

    .fr-thumbnails-horizontal .fr-thumbnails-side-next
        margin-right: 20px


/* vertical */
@media all and (min-width: 1200px)
    .fr-thumbnails-vertical .fr-thumbnails-thumbs
        padding: 8px 16px

    .fr-thumbnails-vertical .fr-thumbnails-side
        margin: 0 8px

    .fr-thumbnails-vertical .fr-thumbnails-side-previous
        margin-top: 16px

    .fr-thumbnails-vertical .fr-thumbnails-side-next
        margin-bottom: 16px

@media all and (min-width: 1800px)
    .fr-thumbnails-vertical .fr-thumbnails-thumbs
        padding: 10px 20px

    .fr-thumbnails-vertical .fr-thumbnails-side
        margin: 10px 0

    .fr-thumbnails-vertical .fr-thumbnails-side-previous
        margin-top: 20px

    .fr-thumbnails-vertical .fr-thumbnails-side-next
        margin-bottom: 20px


/* hide thumbnails on smaller screens
   the js always hides them on touch based devices
*/
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px)
    .fr-thumbnails-horizontal .fr-thumbnails
        display: none !important

@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px)
    .fr-thumbnails-vertical .fr-thumbnails
        display: none !important


/* force fullClick: true
   and force overflow: false */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px)
    .fr-page
        min-width: 100%

    .fr-page
        min-height: 100%


/* switch between ui modes
   we force everything not in the current UI mode to hide
*/
.fr-window-ui-fullclick .fr-side-next-outside,
.fr-window-ui-fullclick .fr-side-previous-outside,
.fr-window-ui-fullclick .fr-close-outside,
.fr-window-ui-fullclick .fr-content .fr-side-next,
.fr-window-ui-fullclick .fr-content .fr-side-previous,
.fr-window-ui-fullclick .fr-content .fr-close,
.fr-window-ui-fullclick .fr-content .fr-info,
.fr-window-ui-outside .fr-side-next-fullclick,
.fr-window-ui-outside .fr-side-previous-fullclick,
.fr-window-ui-outside .fr-close-fullclick,
.fr-window-ui-outside .fr-content .fr-side-next,
.fr-window-ui-outside .fr-content .fr-side-previous,
.fr-window-ui-outside .fr-content .fr-close,
.fr-window-ui-outside .fr-content .fr-info,
.fr-window-ui-inside .fr-page > .fr-info,
.fr-window-ui-inside .fr-side-next-fullclick,
.fr-window-ui-inside .fr-side-previous-fullclick,
.fr-window-ui-inside .fr-close-fullclick,
.fr-window-ui-inside .fr-side-next-outside,
.fr-window-ui-inside .fr-side-previous-outside,
.fr-window-ui-inside .fr-close-outside
    display: none !important


/* some ui elements can be toggled on mousemove.
   filter:alpha(opacity) is bugged so we use display in oldIE
*/
.fr-toggle-ui
    opacity: 0
    -webkit-transition: opacity .3s
    transition: opacity .3s

.fr-visible-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-visible-inside-ui .fr-ui-inside .fr-toggle-ui
    opacity: 1

.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui
    -webkit-transition: opacity .3s
    transition: opacity .3s

.fr-ltIE9.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-ltIE9.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui
    display: none

/* Spinner */
.fr-spinner
    position: fixed
    width: 52px
    height: 52px
    background: #101010
    background: rgba(16,16,16,.85)
    border-radius: 5px

.fr-spinner div
    position: absolute
    top: 0
    left: 0
    height: 64%
    width: 64%
    margin-left: 18%
    margin-top: 18%
    opacity: 1
    -webkit-animation: fresco-12 1.2s infinite ease-in-out
    animation: fresco-12 1.2s infinite ease-in-out

.fr-spinner div:after
    content: ''
    position: absolute
    top: 0
    left: 50%
    width: 2px
    height: 8px
    margin-left: -1px
    background: #fff
    box-shadow: 0 0 1px rgba(0,0,0,0) /* fixes rendering in Firefox */

.fr-spinner div.fr-spin-1
    -ms-transform: rotate(30deg)
    -webkit-transform: rotate(30deg)
    transform: rotate(30deg)
    -webkit-animation-delay: -1.1s
    animation-delay: -1.1s

.fr-spinner div.fr-spin-2
    -ms-transform: rotate(60deg)
    -webkit-transform: rotate(60deg)
    transform: rotate(60deg)
    -webkit-animation-delay: -1.0s
    animation-delay: -1.0s

.fr-spinner div.fr-spin-3
    -ms-transform: rotate(90deg)
    -webkit-transform: rotate(90deg)
    transform: rotate(90deg)
    -webkit-animation-delay: -0.9s
    animation-delay: -0.9s

.fr-spinner div.fr-spin-4
    -ms-transform: rotate(120deg)
    -webkit-transform: rotate(120deg)
    transform: rotate(120deg)
    -webkit-animation-delay: -0.8s
    animation-delay: -0.8s

.fr-spinner div.fr-spin-5
    -ms-transform: rotate(150deg)
    -webkit-transform: rotate(150deg)
    transform: rotate(150deg)
    -webkit-animation-delay: -0.7s
    animation-delay: -0.7s

.fr-spinner div.fr-spin-6
    -ms-transform: rotate(180deg)
    -webkit-transform: rotate(180deg)
    transform: rotate(180deg)
    -webkit-animation-delay: -0.6s
    animation-delay: -0.6s

.fr-spinner div.fr-spin-6
    -ms-transform: rotate(180deg)
    -webkit-transform: rotate(180deg)
    transform: rotate(180deg)
    -webkit-animation-delay: -0.6s
    animation-delay: -0.6s

.fr-spinner div.fr-spin-7
    -ms-transform: rotate(210deg)
    -webkit-transform: rotate(210deg)
    transform: rotate(210deg)
    -webkit-animation-delay: -0.5s
    animation-delay: -0.5s

.fr-spinner div.fr-spin-8
    -ms-transform: rotate(240deg)
    -webkit-transform: rotate(240deg)
    transform: rotate(240deg)
    -webkit-animation-delay: -0.4s
    animation-delay: -0.4s

.fr-spinner div.fr-spin-9
    -ms-transform: rotate(270deg)
    -webkit-transform: rotate(270deg)
    transform: rotate(270deg)
    -webkit-animation-delay: -0.3s
    animation-delay: -0.3s

.fr-spinner div.fr-spin-10
    -ms-transform: rotate(300deg)
    -webkit-transform: rotate(300deg)
    transform: rotate(300deg)
    -webkit-animation-delay: -0.2s
    animation-delay: -0.2s

.fr-spinner div.fr-spin-11
    -ms-transform: rotate(330deg)
    -webkit-transform: rotate(330deg)
    transform: rotate(330deg)
    -webkit-animation-delay: -0.1s
    animation-delay: -0.1s

.fr-spinner div.fr-spin-12
    -ms-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
    -webkit-animation-delay: 0s
    animation-delay: 0s


@-webkit-keyframes fresco-12
    0%
        opacity: 1
    100%
        opacity: 0

@keyframes fresco-12
    0%
        opacity: 1
    100%
        opacity: 0

/* Thumbnail spinner */
.fr-thumbnail-spinner
    position: absolute
    top: 50%
    left: 50%
    margin-top: -20px
    margin-left: -20px
    width: 40px
    height: 40px

.fr-thumbnail-spinner-spin
    position: relative
    float: left
    margin: 8px 0 0 8px
    text-indent: -9999em
    border-top: 2px solid rgba(255, 255, 255, 0.2)
    border-right: 2px solid rgba(255, 255, 255, 0.2)
    border-bottom: 2px solid rgba(255, 255, 255, 0.2)
    border-left: 2px solid #fff
    -webkit-animation: fr-thumbnail-spin 1.1s infinite linear
    animation: fr-thumbnail-spin 1.1s infinite linear

.fr-thumbnail-spinner-spin,
.fr-thumbnail-spinner-spin:after
    border-radius: 50%
    width: 24px
    height: 24px

@-webkit-keyframes fr-thumbnail-spin
    0%
        -webkit-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        transform: rotate(360deg)

@keyframes fr-thumbnail-spin
    0%
        -webkit-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        transform: rotate(360deg)


/* Error */
.fr-error
    float: left
    position: relative
    background-color: #ca3434
    width: 160px
    height: 160px

.fr-error-icon
    position: absolute
    width: 42px
    height: 42px
    top: 50%
    left: 50%
    margin-left: -21px
    margin-top: -21px


/* skin: 'fresco' */

/* Sprite */
.fr-window-skin-fresco .fr-side-button-icon,
.fr-window-skin-fresco .fr-close-icon,
.fr-window-skin-fresco .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon
    background-image: url('/img/fresco/skins/fresco/sprite.svg')

/* fallback png sprite */
.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-close-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon
    background-image: url('/img/fresco/skins/fresco/sprite.png')

.fr-window-skin-fresco .fr-error-icon
    background-position: -160px -126px

.fr-window-skin-fresco .fr-content-background
    background: #101010
    box-shadow: 0 0 5px rgba(0,0,0,.4)

.fr-window-skin-fresco.fr-window-ui-fullclick .fr-content-background
    box-shadow: none

/* thumbnail shadow */
.fr-window-skin-fresco .fr-thumbnail-wrapper
    box-shadow: 0 0 3px rgba(0, 0, 0, .3)

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper
    box-shadow: 0 0 1px rgba(0, 0, 0, .1)


/* < > */
.fr-window-skin-fresco .fr-side-button-background
    background-color: transparent

.fr-window-skin-fresco .fr-side-previous .fr-side-button-icon
    background-position: -13px -14px

.fr-window-skin-fresco .fr-side-next .fr-side-button-icon
    background-position: -93px -14px


.fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon
    background-position: -13px -114px

.fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon
    background-position: -93px -114px


/* transition (png) */
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon
    background-position: -13px -114px

.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon
    background-position: -93px -114px


/* disabled state (png) */
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon
    background-position: -13px -214px

.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon
    background-position: -93px -214px


/* transition (svg) */
.fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon
    background-position: -13px -114px

.fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon
    background-position: -93px -114px

.fr-window-skin-fresco.fr-svg .fr-side-button-icon
    opacity: .5


.fr-window-skin-fresco.fr-svg .fr-side:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-next .fr-side-button-icon
    opacity: 1


.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon
    opacity: .8


/* disabled (svg) */
.fr-window-skin-fresco.fr-svg .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-side-disabled:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side-disabled .fr-side-button-icon
    opacity: .2


/* hide for ui:inside/fullclick, only for the image type */
.fr-window-skin-fresco.fr-window-ui-inside .fr-type-image .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-window-ui-fullclick.fr-showing-type-image .fr-side-disabled .fr-side-button-icon
    background-image: none


/* < > responsive */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px)
    .fr-window-skin-fresco .fr-side-previous .fr-side-button-icon
        background-position: 0px -300px

    .fr-window-skin-fresco .fr-side-next .fr-side-button-icon
        background-position: -48px -300px


    .fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon
        background-position: 0px -360px

    .fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon
        background-position: -48px -360px


    /* transition (png) */
    .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon
        background-position: 0px -360px

    .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon
        background-position: -48px -360px


    /* transition (svg) */
    .fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon
        background-position: 0px -360px

    .fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon
        background-position: -48px -360px


    /* disabled state (png) */
    .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon
        background-position: 0px -420px


    .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
    .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon
        background-position: -48px -420px

/* X */
/* colors */
.fr-window-skin-fresco.fr-window-ui-outside .fr-close-background
    background-color: #363636

.fr-window-skin-fresco.fr-window-ui-outside .fr-close:hover .fr-close-background
    background-color: #434343


.fr-window-skin-fresco.fr-window-ui-inside .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close-background
    background-color: #13131

.fr-window-skin-fresco.fr-window-ui-inside .fr-close:hover .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close:hover .fr-close-background
    background-color: #191919


/* - image */
.fr-window-skin-fresco .fr-close .fr-close-icon
    background-position: -168px -8p

.fr-window-skin-fresco .fr-close:hover .fr-close-icon
    background-position: -210px -8px


/* - transition */
.fr-window-skin-fresco.fr-svg .fr-close .fr-close-icon
  background-position: -210px -8px
  opacity: .8

.fr-window-skin-fresco .fr-close:hover .fr-close-icon
    opacity: 1

/* iOS 8.4.1 bug: when opacity changes it'll require 2 taps
   force a single opacity to fix this
*/
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,
.fr-window-skin-fresco.fr-mobile-touch .fr-close:hover .fr-close-icon
    opacity: 1

/* Thumbnails */
.fr-window-skin-fresco .fr-thumbnail-wrapper
    border-color: transparent
    border-style: solid
    border-width: 0

.fr-window-skin-fresco .fr-thumbnail-wrapper
    box-shadow: 0 0 3px rgba(0, 0, 0, .3)

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper
    box-shadow: 0 0 1px rgba(0, 0, 0, .1)

.fr-window-skin-fresco .fr-thumbnail-wrapper
    box-shadow: 0 -1px 4px rgba(0, 0, 0, .3)

.fr-window-skin-fresco .fr-thumbnail-overlay-border
    border-width: 1px
    border-color: rgba(255, 255, 255, .08) /* should remain rgba */

/* no inner border on active thumbnail */
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-overlay-border,
.fr-window-skin-fresco .fr-thumbnail-active:hover .fr-thumbnail-overlay-border
    border: 0px


/* Thumbnails < > */
.fr-window-skin-fresco .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon
    background-position: -167px -49px

.fr-window-skin-fresco .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon
    background-position: -209px -49px

.fr-window-skin-fresco .fr-thumbnails-side-next .fr-thumbnails-side-button-icon
    background-position: -167px -91px

.fr-window-skin-fresco .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon
    background-position: -209px -91px

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon
    background-position: -293px -49px

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon
    background-position: -335px -49px

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon
    background-position: -293px -91px

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon
    background-position: -335px -91px

/* Thumbnails < > transition */
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-icon
    -moz-transition: opacity .2s ease-in
    -webkit-transition: opacity .2s ease-in
    transition: opacity .2s ease-in
    opacity: .8

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled
    background-position: -167px -49px

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled
    background-position: -209px -91px

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-icon
    opacity: 1

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled
    background-position: -293px -49px

.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled
    background-position: -335px -91px

/* lower opacity on disabled states */
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled
    opacity: .5

/* lower opacity IE < 9 using images */
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon
    background-position: -251px -49px

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon
    background-position: -251px -91px

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon
    background-position: -377px -49px

.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon
    background-position: -377px -91px