.admin-edit-bar {
  background: #0098af;
  padding: 10px 20px 7px 20px;
  color: #fff;
  line-height: 30px;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  
  img {
    margin-top: -8px;
    margin-right: 10px;
  }
  
  .margin-left-20 {
    margin-left: 20px;
  }
  
  a {
    color: #fff;
    display: inline-block;
    
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}